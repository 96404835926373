import { SET_IS_CLIENT, SET_CART_BONUS } from "utils/action-types";

const initialState = {
	isClient: false,
	cartBonus: null,
};

const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_IS_CLIENT:
			return {
				...state,
				isClient: true
			};
		case SET_CART_BONUS:
			return {
				...state,
				cartBonus: action.payload,
			};

		default:
			return state;
	}
};

export default globalReducer;
