// Account profile
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS";
export const CREATE_CUSTOMER_ADDRESS = "CREATE_CUSTOMER_ADDRESS";
export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS";
export const GET_CUSTOMER_ADDRESSES = "GET_CUSTOMER_ADDRESSES";
export const LOAD_MORE_ADDRESSES = "LOAD_MORE_ADDRESSES";
export const CHANGE_DEFAULT_ADDRESS = "CHANGE_DEFAULT_ADDRESS";
export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS";
export const LOAD_MORE_ORDERS = "LOAD_MORE_ORDERS";
export const FETCH_LOYALTY_LION = "FETCH_LOYALTY_LION";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const UPDATE_CUSTOMER_STATE = "UPDATE_CUSTOMER_STATE";

// Customer Loop API
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const LIST_SCHEDULED_ORDERS = "LIST_SCHEDULED_ORDERS";
export const UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS";
export const LIST_PAYMENT_METHODS = "LIST_PAYMENT_METHODS";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const TOGGLE_SCHEDULED_ORDERS_MODAL = "TOGGLE_SCHEDULED_ORDERS_MODAL";
export const TOGGLE_RESCHEDULE_MODAL = "TOGGLE_RESCHEDULE_MODAL";
export const TOGGLE_SUBS_CART = "TOGGLE_SUBS_CART";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const TOGGLE_UPDATING_SUB_PRODUCT = "TOGGLE_UPDATING_SUB_PRODUCT";
export const TOGGLE_UNAVAILABLE_ITEMS = "TOGGLE_UNAVAILABLE_ITEMS";
export const CHANGE_NEXT_BILLING_DATE = "CHANGE_NEXT_BILLING_DATE";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const UPDATE_DELIVERY_FREQUENCY = "UPDATE_DELIVERY_FREQUENCY";

// Cart
export const FETCH_CART = "FETCH_CART";
export const CART_ERROR = "CART_ERROR";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const OPEN_CART = "OPEN_CART";
export const CLOSE_CART = "CLOSE_CART";
export const RESET_REMOVE_ITEM_CLICK = "RESET_REMOVE_ITEM_CLICK";
export const TOGGLE_CHECKOUT_LOADING = "TOGGLE_CHECKOUT_LOADING";
export const SET_MEALS_PER_WEEK = "SET_MEALS_PER_WEEK";
export const SELECT_BOX = "SELECT_BOX";
export const SELECT_MIN_BOX = "SELECT_MIN_BOX";
export const SET_DELIVERY_FREQUENCY = "SET_DELIVERY_FREQUENCY";
export const EDIT_LINE_ITEM = "EDIT_LINE_ITEM";
export const CREATE_LINE_ITEM = "CREATE_LINE_ITEM";
export const REMOVE_LINE_ITEM = "REMOVE_LINE_ITEM";
export const RESET_LINE_ITEMS = "RESET_LINE_ITEMS";
export const UPDATE_LINE_TO_CREATE = "UPDATE_LINE_TO_CREATE";
export const ADD_CART_ITEMS = "ADD_CART_ITEMS";
export const TOGGLE_STICKY_CART = "TOGGLE_STICKY_CART";
export const TOGGLE_SAVE_LOADING = "TOGGLE_SAVE_LOADING";
export const UPDATE_DELIVERY_PRICE = "UPDATE_DELIVERY_PRICE";

// Filters
export const TOGGLE_FILTER_PANEL = "TOGGLE_FILTER_PANEL";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const FILTER_COLLECTION_PRODUCTS = "FILTER_COLLECTION_PRODUCTS";
export const ACTIVE_FILTERS = "ACTIVE_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SEARCH = "SEARCH";
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const APPLY_FILTERS = "APPLY_FILTERS";

// Global
export const SET_IS_CLIENT = "SET_IS_CLIENT";
export const SET_CART_BONUS = "SET_CART_BONUS";
